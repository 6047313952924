// Generic imports
import React, { useState, useRef, useEffect } from "react"
import { connect } from "react-redux"
import { Link, navigate } from "gatsby"

// Other components imports
import Alert from "components/Reusable/Alert"
import Button from "components/Button"
import Input from "components/Reusable/Input"
import ImageFile from "components/Reusable/Input/imagefile"
import SelectKota from "components/Reusable/Input/select-kota"
import { Styles } from "components/EditProfile/style"

// Redux imports
import * as sessionSelectors from "rdx/slices/session/selectors"
import * as sessionThunks from "rdx/slices/session/thunks"
import { trackEditProfile } from "trackers/amplitude"

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {
  editProfile: sessionThunks.editProfile,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const EditProfile = props => {
  const { user, editProfile } = props

  const form = useRef(null)
  const [loading, setLoading] = useState(false)

  const submit = e => {
    e.preventDefault()
    setLoading(true)
    const data = new FormData(form.current)
    data.append("id", user.id)
    editProfile(data)
      .then(() => {
        setTimeout(() => navigate("/", 2000))
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    trackEditProfile()
  }, [])

  return (
    <Styles className="login-register">
      <h1>Complete Your Profile</h1>
      <div style={{ color: "gray" }}>Add your profile photo</div>
      <form ref={form} onSubmit={submit}>
        <div className="ubah-foto">
          <ImageFile name="photo" picture={user.photo} />
        </div>
        <Input
          id="first_name"
          name="first_name"
          type="text"
          placeholder="First Name"
          label="First Name*"
          defaultValue={user.first_name}
          required
        />
        <Input
          id="last_name"
          name="last_name"
          type="text"
          placeholder="Last Name"
          label="Last Name*"
          defaultValue={user.last_name}
          required
        />
        <Input
          id="pekerjaan"
          name="occupation"
          type="text"
          placeholder="Pekerjaan"
          label="Pekerjaan*"
          defaultValue={user.occupation}
          required
        />
        <Input
          id="instansi"
          name="instansi"
          type="text"
          placeholder="Instansi"
          label="Instansi*"
          defaultValue={user.instansi}
          required
        />
        <SelectKota
          id="kota"
          name="kota"
          label="Kota"
          defaultChecked={user.kota?.id}
        />
        <br />
        <br />
        <Alert>
          You can edit this data again in settings menu on your dashboard
        </Alert>
        <div className="verification-body">
          <Button className="action-btn" disabled={loading}>
            SAVE
          </Button>
        </div>
        <div className="skip-invisible-btn">
          <Link to="/">SKIP</Link>
        </div>
      </form>
    </Styles>
  )
}

export default withConnect(EditProfile)
