// Generic imports
import React from "react"

// Other components imports
import EditProfile from "components/EditProfile"
import Layout from "components/layout"
import SEO from "components/seo"

// Redux imports

// Static imports

// Utils imports

const EditProfilePage = () => (
  <Layout>
    <SEO title="Edit profile" />
    <EditProfile />
  </Layout>
)

export default EditProfilePage
