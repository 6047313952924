// Generic imports
import styled from "styled-components"

// Static imports

export const Styles = styled.div`
  form {
    text-align: left;
  }

  .verification-body {
    padding-top: 3rem;
  }

  @media (max-width: 767px) {
  }
`
